import { createSelector } from '@ngrx/store';
import {
  ActionTypes,
  TypeAction
} from './agreement.actions';
import { IRootState } from '../../store';
import { IOption } from '../../shared/components/select';
import {
  createSelectModelFromAnyArray,
  createSelectModelFromStringArray,
  ICreateSelectModelPropsSetting
} from '../../shared/components/select/models/createSelectModel';
import { MONTH_NAMES } from '../../core/services/constants';
import {
  countriesNames,
  DepartmentsEnum
} from '../abstract.components/constants';
import { PEActivationStatusEnum } from './constants';
import { ValidationTypesEnum } from '../../core/services/employee/employee.service';
import {
  EmployeeStatusEnum,
  IContactConvertationData
} from '../../core/services/employee/interfaces';

export enum FieldsAsyncValidationEnum {
  ssnAndDob,
  primaryPhone,
  alternatePhone,
  emergencyPrimaryPhone,
  emergencyAlternatePhone,
  addressValidationInfo,
}

export enum TipTypesEnum {
  antiDescrNotice,
  ethnicity,
  race
}

export interface IAgreementOptions {
  phoneType: any[];
  college: any[];
  relationship: any[];
  state: any[];
  race: any[];
  gender: any[];
  ethnicity: any[];
  citizenship: any[];
  maritalStatus: any[];
  militaryStatus: any[];
  housing: any[];
}

export interface ISelectModels {
  phoneType: IOption[];
  college: IOption[];
  relationship: IOption[];
  state: IOption[];
  gender: IOption[];
  ethnicity: IOption[];
  race: IOption[];
  citizenship: IOption[];
  maritalStatus: IOption[];
  militaryStatus: IOption[];
}

export interface ISsnAndDobValidity {
  valid: boolean;
  message: string | null;
}

export interface IAddressFields {
  addressLine: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IValidatedAddressFields {
  valid: boolean;
  city: string;
  state: string;
}

export interface IWDformData {
  dateOfBirth: string;
  ssn: string;
  gender: string;
  ethnicity: string;
  race?: string | null;
  citizenship: string;
  maritalStatus: string;
  militaryStatus: string | null;
}

export interface IStepOneFormData extends IWDformData {
  legalFirstName: string;
  preferredFirstName: string;
  legalMiddleName: string;
  legalLastName: string;
  email: string;
  confirmEmail?: string;
  primaryPhone: string;
  primaryPhoneType: number | null;
  alternatePhone: string | null;
  alternatePhoneType: number | null;
  college: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryState: string;
  postalCode: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPrimaryPhone: string;
  emergencyAlternatePhone: string;
  emergencyPrimaryPhoneType: number | null;
  emergencyAlternatePhoneType: number | null;
  emergencyRelationship: number | null;
  housing?: string | number | null;
}

export interface IStepTwoFormData {
  signature: string;
}

export interface IAgreementDetails {
  country: string;
  publicKey: string;
  seasonName: string;
  signerName: string;
  signerEmail: string
}

export enum UserTypeEnum {
  Contact = 'Contact',
  PastEmployee = 'PastEmployee',
  Recruit = 'Recruit',
  Manager = 'Manager'
}

export interface IFieldsLoadingProgress {
  ssnAndDob: boolean;
  primaryPhone: boolean;
  alternatePhone: boolean;
  emergencyPrimaryPhone: boolean;
  emergencyAlternatePhone: boolean;
  addressValidationInfo: boolean;
}

export interface IIsEmergencyContactsExist {
  emergencyFirstName: boolean;
  emergencyLastName: boolean;
  emergencyPrimaryPhone: boolean;
  emergencyAlternatePhone: boolean;
  emergencyRelationship: boolean;
}

export enum FetchingStatusEnum {
  initial,
  fetched,
  error
}

export interface IFetchingState {
  options: FetchingStatusEnum;
  terms: FetchingStatusEnum;
  pastEmployeeData: FetchingStatusEnum;
  agreement: FetchingStatusEnum;
  storage: FetchingStatusEnum;
}

export enum FetchingPropsEnum {
  options,
  terms,
  pastEmployeeData,
  agreement,
  storage,
}

export enum NotificationTypesEnum {
  email,
  sms
}

export interface State {
  isUserVerificationCompleted: boolean;
  department: DepartmentsEnum;
  notificationType: NotificationTypesEnum;
  isSigned: boolean;
  hasSetPassword: boolean;
  userType: UserTypeEnum;
  employeeId: number | null;
  country: string;
  publicKey: string;
  seasonName: string;
  signerName: string;
  signerLastName: string;
  signerPreferredName: string;
  signerEmail: string;
  disclosureApproval: boolean;
  disclosureChecked: boolean;
  pastEmployeeStatus: PEActivationStatusEnum;
  agreementOptions: IAgreementOptions;
  passedSteps: string;
  stepOne: {
    formData: IStepOneFormData;
    college: {
      query: string;
    };
    queryState: string;
    ssnAndDobValidity: ISsnAndDobValidity;
    foundedEmployeeStatus: EmployeeStatusEnum;
    phonesValidity: {
      primaryPhone: ValidationTypesEnum;
      alternatePhone: ValidationTypesEnum;
      emergencyPrimaryPhone: ValidationTypesEnum;
      emergencyAlternatePhone: ValidationTypesEnum;
    };
    addressValidity: {
      valid: boolean;
      city: string;
      state: string;
    };
    fieldsLoadingProgress: IFieldsLoadingProgress;
    isEmergencyContactsExist: IIsEmergencyContactsExist;
  };
  stepTwo: {
    formData: IStepTwoFormData;
  };
  terms: string;
  welcome: {
    employeeId: number | null;
    formData: {
      lastSsnDigits: string;
      dateOfBirth: string;
      ssn: string;
      email: string;
    };
    currentStep: string;
    verified: boolean;
    stepTwoError: boolean;
  };
  fetching: IFetchingState;
  setPassword: {
    userName: string;
    recruitName: string;
    recruitEmail: string;
    formData: {
      password: string;
      confirmPassword: string;
    }
  };
  tip: {
    antiDescrNotice: boolean;
    ethnicity: boolean;
    race: boolean;
  },
  guard: boolean
}

const initialState: State = {
  isUserVerificationCompleted: false,
  department: DepartmentsEnum.Unknown,
  notificationType: NotificationTypesEnum.email,
  isSigned: false,
  hasSetPassword: false,
  userType: UserTypeEnum.PastEmployee,
  employeeId: null,
  country: '',
  publicKey: '',
  seasonName: '',
  signerName: '',
  signerLastName: '',
  signerPreferredName: '',
  signerEmail: '',
  disclosureApproval: false,
  disclosureChecked: false,
  pastEmployeeStatus: PEActivationStatusEnum.Inactive,

  agreementOptions: {
    phoneType: [],
    college: [],
    relationship: [],
    state: [],
    race: [],
    gender: [],
    ethnicity: [],
    citizenship: [],
    maritalStatus: [],
    militaryStatus: [],
    housing: []
  },

  passedSteps: 'step-one',

  stepOne: {
    formData: {
      legalFirstName: '',
      preferredFirstName: '',
      legalMiddleName: '',
      legalLastName: '',
      dateOfBirth: '',
      ssn: '',
      gender: '',
      ethnicity: '',
      race: '',
      citizenship: '',
      maritalStatus: '',
      militaryStatus: null,
      email: '',
      confirmEmail: '',
      primaryPhone: '',
      primaryPhoneType: null,
      alternatePhone: null,
      alternatePhoneType: null,
      college: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      countryState: '',
      postalCode: '',
      emergencyFirstName: '',
      emergencyLastName: '',
      emergencyPrimaryPhone: '',
      emergencyAlternatePhone: '',
      emergencyPrimaryPhoneType: null,
      emergencyAlternatePhoneType: null,
      emergencyRelationship: null,
      housing: null
    },
    college: {
      query: '',
    },
    queryState: '',
    ssnAndDobValidity: {
      valid: false,
      message: null,
    },
    foundedEmployeeStatus: EmployeeStatusEnum.None,
    phonesValidity: {
      primaryPhone: ValidationTypesEnum.initial,
      alternatePhone: ValidationTypesEnum.initial,
      emergencyPrimaryPhone: ValidationTypesEnum.initial,
      emergencyAlternatePhone: ValidationTypesEnum.initial,
    },
    addressValidity: {
      valid: false,
      city: '',
      state: '',
    },
    fieldsLoadingProgress: {
      ssnAndDob: false,
      primaryPhone: false,
      alternatePhone: false,
      emergencyPrimaryPhone: false,
      emergencyAlternatePhone: false,
      addressValidationInfo: false,
    },
    isEmergencyContactsExist: {
      emergencyFirstName: false,
      emergencyLastName: false,
      emergencyPrimaryPhone: false,
      emergencyAlternatePhone: false,
      emergencyRelationship: false,
    }
  },
  stepTwo: {
    formData: {
      signature: ''
    }
  },
  terms: '',
  welcome: {
    employeeId: null,
    formData: {
      lastSsnDigits: '',
      dateOfBirth: '',
      ssn: '',
      email: ''
    },
    currentStep: 'step-one',
    verified: false,
    stepTwoError: false
  },
  fetching: {
    options: FetchingStatusEnum.initial,
    terms: FetchingStatusEnum.initial,
    pastEmployeeData: FetchingStatusEnum.initial,
    agreement: FetchingStatusEnum.initial,
    storage: FetchingStatusEnum.initial,
  },
  setPassword: {
    userName: '',
    recruitName: '',
    recruitEmail: '',
    formData: {
      password: '',
      confirmPassword: ''
    }
  },
  tip: {
    antiDescrNotice: false,
    ethnicity: false,
    race: false,
  },
  guard: null
};

const getUpdatedStep = (formData: any, step: any) => formData ? Object.assign({}, step, { formData: {
    ...step.formData,
    ...formData
  } }) : step;

export function getCurrentDateObject() {
  const currentDate = new Date();
  return {
    todayDate: currentDate.getDate(),
    todayMonth: MONTH_NAMES[currentDate.getMonth()],
    todayYear: currentDate.getFullYear()
  };
}

export function agreementReducer(state: State = initialState, action: TypeAction): State {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_STORAGE_DATA: {
      const {
        stepOne,
        stepTwo
      }: State = state;

      const [
        stepOneData,
        stepTwoData,
        passedSteps] = payload;

      const stepOneResult = getUpdatedStep(stepOneData, stepOne);
      const stepTwoResult = getUpdatedStep(stepTwoData, stepTwo);
      const fetchingResult = {
        ...state.fetching,
        storage: FetchingStatusEnum.fetched
      };

      return Object.assign({}, state,
        { stepOne: stepOneResult, stepTwo: stepTwoResult, passedSteps, fetching: fetchingResult });
    }

    case ActionTypes.PRE_POPULATE_FORM: {
      const {
        stepOne,
        stepTwo
      }: State = state;

      const stepOneResult = getUpdatedStep(payload.stepOne, stepOne);
      const stepTwoResult = getUpdatedStep(payload.stepTwo, stepTwo);

      return Object.assign({}, state,
        { stepOne: stepOneResult, stepTwo: stepTwoResult });
    }

    case ActionTypes.STORE_AGREEMENT_DETAILS: {
      return Object.assign({}, state, payload);
    }

    case ActionTypes.STORE_IS_EMERGENCY_EXIST: {
      const result = {
        ...state.stepOne,
        isEmergencyContactsExist: payload
      };

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.SET_DISCLOSURE_APPROVAL: {
      return Object.assign({}, state, { disclosureApproval: payload });
    }

    case ActionTypes.TOGGLE_DISCLOSURE_CHECKBOX: {
      return Object.assign({}, state, { disclosureChecked: !state.disclosureChecked });
    }

    case ActionTypes.SET_SELECT_MODELS: {
      const { agreementOptions } = state;
      const result = {
        ...agreementOptions,
        ...payload
      };

      return Object.assign({}, state, { agreementOptions: result });
    }

    case ActionTypes.STORE_SSN_AND_DOB_VALIDITY: {
      const { stepOne }: State = state;
      const { ssnAndDobValidity } = stepOne;
      const changes = {
        ...ssnAndDobValidity,
        ...payload,
      };
      const result = Object.assign({}, stepOne, { ssnAndDobValidity: changes });

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.WELCOME_COMPLETE_STEP_ONE: {
      const result = Object.assign({}, state.welcome, {
        currentStep: 'step-two',
        verified: !payload.nextStep
      });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.UPDATE_STEP: {
      const nextSteps = {
        'step-one': 'step-two',
        'step-two': 'step-three',
        'step-three': 'step-four',
        'step-four': 'step-four',
      };
      const nextPassedSteps = state.passedSteps === payload.step ?
        nextSteps[state.passedSteps] :
        state.passedSteps;

      return Object.assign({},
        state,
        {
          passedSteps: nextPassedSteps,
          currentStep: nextSteps[payload.step]
        });
    }

    case ActionTypes.SEARCH_SCHOOL: {
      const { stepOne }: State = state;
      const { college } = stepOne;
      const changes = Object.assign({}, college, {
        query: payload
      });
      const result = Object.assign({}, stepOne, { college: changes });

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.SEARCH_STATE: {
      const { stepOne }: State = state;
      const result = Object.assign({}, stepOne, { queryState: payload });

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.UPDATE_FORM_DATA: {
      const { stepOne }: State = state;
      // eslint-disable-next-line no-prototype-builtins
      const step = stepOne.formData.hasOwnProperty(payload.fieldId) ? 'stepOne' : 'stepTwo';
      const { formData } = state[step];

      const resFormData = Object.assign({}, formData, { [payload.fieldId]: payload.value });
      const result = Object.assign({}, state[step], { formData: resFormData });

      return Object.assign({}, state, { [step]: result });
    }

    case ActionTypes.SET_AGREEMENT_TERMS: {
      return Object.assign({}, state, { terms: payload });
    }

    case ActionTypes.STORE_PAST_EMPLOYEE_FORM_DATA: {
      const formData = Object.assign({}, state.stepOne.formData, payload);
      const stepOne = Object.assign({}, state.stepOne, { formData });

      return Object.assign({}, state, { stepOne });
    }

    case ActionTypes.GO_TO_STEP: {
      return Object.assign({}, state, { currentStep: payload });
    }

    case ActionTypes.UPDATE_WELCOME_FORM_DATA: {
      const formData = Object.assign({}, state.welcome.formData);

      formData[payload.fieldId] = payload.value;

      const result = Object.assign({}, state.welcome, {
        formData,
        stepTwoError: false
      });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.WELCOME_SAVE_EMPLOYEE_ID: {
      const { welcome } = state;

      const result = Object.assign({}, welcome, { employeeId: payload });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.WELCOME_UPDATE_CURRENT_STEP: {
      const result = Object.assign({}, state.welcome, { currentStep: payload });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.WELCOME_COMPLETE_VERIFICATION: {
      const result = Object.assign({}, state.welcome, { verified: true });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.SHOW_WELCOME_STEP_TWO_ERROR: {
      const result = Object.assign({}, state.welcome, { stepTwoError: true });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.CLEAR_WELCOME_STEP_ERROR: {
      const result = Object.assign({}, state.welcome, { stepTwoError: false });

      return Object.assign({}, state, { welcome: result });
    }

    case ActionTypes.FETCHING_END: {
      return Object.assign({}, state, {
        fetching: {
          ...state.fetching,
          [FetchingPropsEnum[payload.field]]: payload.status
        }
      });
    }

    case ActionTypes.UPDATE_SET_PASSWORD_FORM_DATA: {
      const formData = Object.assign({}, state.setPassword.formData, {
        [payload.fieldId]: payload.value
      });

      const setPassword = Object.assign({}, state.setPassword, { formData });

      return Object.assign({}, state, { setPassword });
    }

    case ActionTypes.SET_SIGNER_DATA: {
      const setPassword = Object.assign({}, state.setPassword, payload);

      return Object.assign({}, state, { setPassword });
    }

    case ActionTypes.AGREEMENT_LOAD_END: {
      return Object.assign({}, state, { isSigned: true });
    }

    case ActionTypes.TRANSFER_CONTACT_TO_PAST_EMPLOYEE: {
      return Object.assign({}, state, { userType: UserTypeEnum.PastEmployee });
    }

    case ActionTypes.SET_TIP_STATE: {
      return Object.assign({}, state, { tip: {
          ...state.tip,
          [TipTypesEnum[payload.type]]: payload.state
        }
      });
    }

    case ActionTypes.SET_FIELD_LOADING_STATE: {
      const { stepOne }: State = state;
      const { fieldsLoadingProgress } = stepOne;
      const changes = {
        ...fieldsLoadingProgress,
        [FieldsAsyncValidationEnum[payload.field]]: payload.isLoading
      };
      const result = Object.assign({}, stepOne, { fieldsLoadingProgress: changes });

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.SET_PHONE_VALIDATION: {
      const { stepOne }: State = state;
      const { phonesValidity } = stepOne;

      const changes = {
        ...phonesValidity,
        [FieldsAsyncValidationEnum[payload.field]]: payload.valid
      };

      const result = Object.assign({}, stepOne, { phonesValidity: changes });
      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.SET_ADDRESS_VALIDATION: {
      const { stepOne }: State = state;
      const { addressValidity } = stepOne;
      const changes = {
        ...addressValidity,
        valid: payload.valid,
        city: payload.city,
        state: payload.state
      };
      const result = Object.assign({}, stepOne, { addressValidity: changes });

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.SET_FOUNDED_EMPLOYEE_STATUS: {
      const { stepOne }: State = state;

      const result = Object.assign({}, stepOne, { foundedEmployeeStatus: payload });

      return Object.assign({}, state, { stepOne: result });
    }

    case ActionTypes.USER_VERIFICATION_COMPLETED: {
      return Object.assign({}, state, { isUserVerificationCompleted: true });
    }

    case ActionTypes.RESET_AGREEMENT: {
      return Object.assign({}, state, { publicKey: '' });
    }

    case ActionTypes.GUARD_AGREEMENT: {
      return Object.assign({}, state, { guard: payload });
    }

    default: {
      return state;
    }
  }
}

export const getSelectModels = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State): ISelectModels => {
    const { agreementOptions }: State = state;
    const selectSetting: ICreateSelectModelPropsSetting = {
      valueKey: 'value',
      labelKey: 'text'
    };

    const selectModels = Object.keys(agreementOptions).reduce((acc, key) => {
      const propValue = typeof agreementOptions[key][0] !== 'object' ?
        createSelectModelFromStringArray(agreementOptions[key]) :
        createSelectModelFromAnyArray(agreementOptions[key], selectSetting);

      acc[key] = propValue;

      return acc;
    }, Object.assign({}, agreementOptions));

    return selectModels;
  }
);

export const getPassedSteps = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ passedSteps }: State) => passedSteps
);

export const getDisclosureApproval = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    return state.disclosureApproval;
  }
);

export const getDisclosureCheckbox = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    return state.disclosureChecked;
  }
);

export const getAgreementDetails = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    return {
      notificationType: state.notificationType,
      isSigned: state.isSigned,
      hasSetPassword: state.hasSetPassword,
      userType: state.userType,
      employeeId: state.employeeId,
      country: state.country,
      publicKey: state.publicKey,
      seasonName: state.seasonName,
      signerName: state.signerName,
      signerLastName: state.signerLastName,
      signerPreferredName: state.signerPreferredName,
      signerEmail: state.signerEmail,
      pastEmployeeStatus: state.pastEmployeeStatus,
      department: state.department,
    };
  }
);

export const getStepOneFormData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    return state.stepOne.formData;
  }
);

export const getStepTwoFormData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    return state.stepTwo.formData;
  }
);

export const getFilteredColleges = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State): IOption[] => {
    const {
      agreementOptions: { college },
      stepOne: { college: { query } }
    } = state;

    return college
      .filter((school) => school.text
        .toLowerCase()
        .includes(query.toLowerCase()))
      .map((school) => ({
        value: school.value,
        label: school.text
      }));
  }
);

export const getFilteredStates = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State): IOption[] => {
    const {
      agreementOptions: { state: countryStates },
      stepOne: { queryState }
    } = state;

    return countryStates
      .filter((countryState) => countryState.text
        .toLowerCase()
        .includes(queryState.toLowerCase()))
      .map(({ value, text }) => ({ value, label: text }));
  }
);

export const getFilteredRaces = createSelector(
  ({agreement}: IRootState) => agreement,
  (state: State): IOption[] => {
    const {
      agreementOptions: { race },
      stepOne: { formData }} = state;
    console.log("Ethnicity: " + formData.ethnicity);
    if (formData.ethnicity === null || formData.ethnicity === undefined) {
      return race.map((x) => ({
        value: x.value,
        label: x.text
      }));
    }
    const nonHispanicEthnicities = [0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const hispanicEthnicities = [2];
    return nonHispanicEthnicities.indexOf(formData.ethnicity as any as number) != -1?
    race.filter((x) => nonHispanicEthnicities.indexOf(x.value) != -1)
    .map((x) => ({
      value: x.value,
      label: x.text
    }))
: race.filter((x) => hispanicEthnicities.indexOf(x.value) != -1)
    .map((x) => ({
      value: x.value,
      label: x.text
    }));
  },
);

export const getSsnAndDobValidity = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne }: State): ISsnAndDobValidity => stepOne.ssnAndDobValidity
);

export const getAgreementTerms = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    let stateName = state.agreementOptions.state.find((countryState) =>
      Number(countryState.value) === Number(state.stepOne.formData.countryState));
    stateName = stateName ? stateName.text : '';

    return {
      text: state.terms,
      properties: Object.assign(
        {},
        getCurrentDateObject(),
        state.stepOne.formData,
        { countryState: stateName }
      )
    };
  }
);

export const getAgreementModels = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => state.agreementOptions
);

export const getAgreementFormData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    return Object.assign(
      {},
      state.stepOne.formData,
      state.stepTwo.formData,
      { userType: state.userType },
      {
        college: state.agreementOptions.college.find((college) =>
          Number(college.value) === Number(state.stepOne.formData.college))
      }
    );
  }
);

export const getWelcomeStepOneFormData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    const {
      lastSsnDigits,
      dateOfBirth
    } = state.welcome.formData;

    return {
      lastSsnDigits,
      dateOfBirth
    };
  }
);

export const getWelcomeStepTwoFormData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    const {
      ssn,
      dateOfBirth,
      email
    } = state.welcome.formData;

    return {
      ssn,
      dateOfBirth,
      email
    };
  }
);

export const getWelcomeStepTwoData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => {
    const {
      country,
      userType,
      employeeId,
      publicKey,
      signerName,
      signerLastName,
      signerPreferredName,
      welcome: {
        formData,
        employeeId: welcomeEmployeeId
      }
    } = state;

    return {
      publicKey,
      signerInfo: {
        userType,
        employeeId: welcomeEmployeeId ? welcomeEmployeeId : employeeId,
        signerName,
        signerLastName,
        signerPreferredName
      },
      isCanada: country === countriesNames.canada,
      formData
    };
  }
);

export const getWelcomeCurrentStep = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => state.welcome.currentStep
);

export const getWelcomeVerificationStatus = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State): boolean => state.welcome.verified
);

export const getWelcomeStepTwoError = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ welcome }: State): any => welcome.stepTwoError
);

export const getIsEmergencyContactsExist = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne }: State): IIsEmergencyContactsExist => stepOne.isEmergencyContactsExist
);

export const getIsSsnAndDobLoading = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne: { fieldsLoadingProgress } }: State): boolean =>
    fieldsLoadingProgress.ssnAndDob
);

export const getPropFetchingStatus = (prop: FetchingPropsEnum) => createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ fetching }: State): FetchingStatusEnum => fetching[FetchingPropsEnum[prop]]
);

export const getSetPasswordFormData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => state.setPassword.formData
);

export const getNewPasswordData = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State) => ({
    agreementId: state.publicKey,
    email: state.welcome.formData.email || state.stepOne.formData.email || null,
    password: state.setPassword.formData.password
  })
);

export const getSignerData = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ userType, setPassword }: State) => ({
    userType,
    login: setPassword.userName,
    name: setPassword.recruitName,
    email: setPassword.recruitEmail
  })
);

export const getIsTipOpen = (type: TipTypesEnum) => createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ tip }: State) => tip[TipTypesEnum[type]]
);

export const getIsFieldLoading = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne: { fieldsLoadingProgress } }: State) => fieldsLoadingProgress[FieldsAsyncValidationEnum[field]]
);

export const getPhoneValidity = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne: { phonesValidity } }: State) => phonesValidity[FieldsAsyncValidationEnum[field]]
);

export const getAddressValidity = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne: { addressValidity } }: State) => addressValidity
);

export const getIsTech = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ department }: State): boolean => department === DepartmentsEnum.Tech
);

export const getIsActiveEmployeeFounded = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne: { foundedEmployeeStatus } }: State): boolean =>
    foundedEmployeeStatus === EmployeeStatusEnum.Active
);

export const getIsInactiveEmployeeFounded = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ stepOne: { foundedEmployeeStatus } }: State): boolean =>
    foundedEmployeeStatus === EmployeeStatusEnum.Inactive
);

export const getIsUserVerificationCompleted = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ isUserVerificationCompleted }: State): boolean => isUserVerificationCompleted
);

export const getAgreementGuard = createSelector(
  ({ agreement }: IRootState) => agreement,
  ({ guard }: State): boolean => guard
);

export const getDataToContactConvert = createSelector(
  ({ agreement }: IRootState) => agreement,
  (state: State): IContactConvertationData => {
    const {
      employeeId,
      country,
      publicKey,
      stepOne: {
        foundedEmployeeStatus,
        formData: {
          ssn,
          dateOfBirth
        },
      }
    } = state;

    return {
      sin: country === countriesNames.canada
        ? ssn.replace(/-/g, '')
        : null,
      ssn: country === countriesNames.canada
        ? null
        : ssn.replace(/-/g, ''),
      birthDate: foundedEmployeeStatus === EmployeeStatusEnum.Active ? null : dateOfBirth,
      isAccountActive: foundedEmployeeStatus === EmployeeStatusEnum.Active,
      agreementId: publicKey,
      employeeId
    };
  }
);
